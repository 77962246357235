import React, { useEffect, useState } from "react";
import ForecastChart from "./componets/forcast";
import { motion } from "framer-motion";

const CACHE_KEY = "myfxbook_data";
const CACHE_TIME = 60 * 60 * 1000; // 1 hour
const StatCard = ({ title, value, icon, trend, color = "blue" }) => (
  <motion.div
    className="bg-white/15 backdrop-blur-md rounded-xl p-4 transition-all duration-300 hover:bg-white/20 hover:transform hover:scale-105 shadow-lg border border-white/10"
    whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
    whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
  >
    <div className="flex items-center justify-between mb-2">
      <motion.span
        className={`text-${color}-400 text-xl`}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
      >
        {icon}
      </motion.span>
      {trend && (
        <motion.span
          className={`text-sm ${trend > 0 ? 'text-green-400' : 'text-red-400'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.2 } }}
        >
          {trend > 0 ? "↑" : "↓"} {Math.abs(trend)}%
        </motion.span>
      )}
    </div>
    <h3 className="text-gray-400 text-sm font-medium">{title}</h3>
    <motion.p
      className="text-white text-lg font-bold mt-1"
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}
    >
      {value}
    </motion.p>
  </motion.div>
);


const MyFxBookData = ({ children, showWidget = true }) => {
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const refreshInterval = 5 * 60 * 1000; // 5 minutes

  // Function to update accountData in Background.js
  const handleAccountDataChange = (updatedAccountData) => {
    setAccountData(updatedAccountData);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
          const { timestamp, data } = JSON.parse(cachedData);
          if (Date.now() - timestamp < CACHE_TIME) {
            const account = Array.isArray(data.accounts)
              ? data.accounts.find((account) => account.id === 11164432)
              : null;
            if (account) {
              setAccountData(account);
            }
            setIsLoading(false);
            return;
          }
        }

        const response = await fetch(
          `https://www.myfxbook.com/api/get-my-accounts.json?session=${process.env.REACT_APP_MYFXBOOK_API_KEY}`
        );
        if (!response.ok) throw new Error("Failed to fetch data");

        const data = await response.json();
        const account = Array.isArray(data.accounts)
          ? data.accounts.find((account) => account.id === 11164432)
          : null;

        if (account) {
          setAccountData(account);
          localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({
              timestamp: Date.now(),
              data,
            })
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(); // Initial fetch

    const intervalId = setInterval(fetchData, refreshInterval);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [refreshInterval]);

  return (
    <div className="relative min-h-screen w-full overflow-hidden font-sans">
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      >
        <source src="/api/placeholder/400/320" type="video/webm" />
        <source
          src="https://res.cloudinary.com/adiec7/video/upload/v1728749526/3%E6%9C%8811%E6%97%A5_2_.mov.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Gradient Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black/80 via-black/70 to-black/80" />
      {/* Additional overlay for better text contrast */}
      <div className="absolute top-0 left-0 w-full h-full bg-black/40" />

      {/* Content Container */}
      <div className="relative z-10 h-screen overflow-y-auto mt-10">
        <div className="max-w-7xl mx-auto p-6 mb-24 mt-24">
          {isLoading ? (
            <div className="flex items-center justify-center min-h-screen">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center p-4">{error}</div>
          ) : accountData ? (
            <div className="space-y-6 pb-24">
              <div className="text-center mb-8">
                <h1 className="text-4xl font-bold mb-2 text-white">
                  HON FUND
                </h1>
                <p className="text-gray-400 max-w-2xl mx-auto">
                  This strategy combines trend-following with regression toward
                  the mean principles to capture market momentum and adjust
                  positions based on market shifts.
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <StatCard
                  title="Total Gain"
                  value={`${accountData.gain}%`}
                  icon="📈"
                  trend={parseFloat(accountData.gain)}
                />
                <StatCard
                  title="Balance"
                  value={`$${accountData.balance.toLocaleString()}`}
                  icon="💰"
                  color="green"
                />
                <StatCard
                  title="Equity"
                  value={`$${accountData.equity.toLocaleString()}`}
                  icon="💎"
                  color="purple"
                />
                <StatCard
                  title="Drawdown"
                  value={`${accountData.drawdown}%`}
                  icon="📉"
                  color="red"
                  trend={-parseFloat(accountData.drawdown)}
                />
                <StatCard
                  title="Daily Growth"
                  value={`${accountData.daily}%`}
                  icon="📅"
                  trend={parseFloat(accountData.daily)}
                />
                <StatCard
                  title="Monthly Growth"
                  value={`${accountData.monthly}%`}
                  icon="📆"
                  trend={parseFloat(accountData.monthly)}
                />
              </div>

              {/* Pass onAccountDataChange correctly */}
              {accountData && (
                <ForecastChart
                  accountData={accountData}
                  onAccountDataChange={handleAccountDataChange}
                />
              )}

              <div className="text-center mt-6 text-gray-400">
                <p>Last Updated: {accountData.lastUpdateDate}</p>
                <a
                  href={
                    accountData.invitationUrl ||
                    "https://www.myfxbook.com/members/Adiec7/hon-fund/11164432"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Verified by Myfxbook
                </a>
              </div>
            </div>
          ) : (
            <div className="text-center">No account data available.</div>
          )}
        </div>
      </div>

      {/* Children content */}
      <div className="relative z-20">{children}</div>
    </div>
  );
};

export default MyFxBookData;