// VantaLayout.jsx
import React from 'react';
import VantaBackground from './vantabackground';

const VantaLayout = ({ children }) => (
  <div className="relative min-h-screen w-full overflow-hidden font-sans">
    {/* Vanta Background - Send to back */}
    <div className="absolute inset-0 z-0">
      <VantaBackground
        color={0x0d47a1}
        backgroundColor={0x000000}
        scale={1.4}
        scaleMobile={1.0}
      />
    </div>

    {/* Gradient Overlay */}
    <div className="absolute inset-0 z-10 bg-gradient-to-b from-black/80 via-black/70 to-black/80" />
    
    {/* Additional overlay for better text contrast */}
    <div className="absolute inset-0 z-10 bg-black/40" />

    {/* Content Container - Bring to front */}
    <div className="relative z-20 min-h-screen">
      {children}
    </div>
  </div>
);

export default VantaLayout;