import React from "react";

const Agreement = () => {

  return (

    <>

      <div

        style={{

          background: "rgba(255, 255, 255, 0.3)",

          backdropFilter: "blur(10px)",

          WebkitBackdropFilter: "blur(10px)",

          borderRadius: "0.5rem",

          padding: "2rem",

          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",

          width: "100%",

          maxWidth: "80%",

          margin: "0 auto",

          maxHeight: "80vh",

          overflowY: "auto",

          color: "whitesmoke",

          zIndex: 1,

          marginTop: "10vh",

        }}

      >

        <h2

          style={{

            fontSize: "1.5rem",

            fontWeight: "bold",

            marginBottom: "1rem",

            textAlign: "center",

          }}

        >

          INVESTMENT AGREEMENT

        </h2>

        <p style={{ marginBottom: "1rem" }}>

          This Investment Agreement (the "Agreement") is made and entered into

          as of the date of submission by the undersigned investor (hereinafter

          referred to as "Investor") and KS Quantitative, a subsidiary of KS

          Market Ltd, a corporation duly registered and existing under the laws

          of the Federal Republic of Nigeria, with Corporate Affairs Commission

          Registration Number 1866331 (hereinafter referred to as "KS Market").

        </p>

        <h3 style={{ fontWeight: "bold" }}>1. About KS Market:</h3>

        <p>

          KS Market Ltd is a leading financial services provider specialising in

          algorithmic trading fund management. With a proven track record of

          success and expertise in the field, KS Market utilises cutting-edge

          technology and advanced money management strategies to deliver

          superior returns to its investors.

        </p>

        <h3 style={{ fontWeight: "bold" }}>2. Scope of Agreement:</h3>

        <p>

          This Agreement governs the terms and conditions of the investment made

          by the Investor in KS Market's algorithmic trading fund management

          system, hereinafter referred to as "Hon." The investment shall be used

          by KS Market exclusively for trading activities within the scope of

          Hon's investment strategy.

        </p>

        <h3 style={{ fontWeight: "bold" }}>3. Performance Commitment:</h3>

        <p>

          KS Market shall use its best efforts to achieve an annual performance

          target within the range of 50-100%, based on the historical

          performance of Hon's trading activities. KS Market does not guarantee

          this return, and performance is subject to market conditions and other

          external factors that may impact trading outcomes.

        </p>

        <h3 style={{ fontWeight: "bold" }}>4. Profit Withdrawal:</h3>

        <p>

          The Investor may withdraw profits every three (3) months, subject to

          the following conditions:

        </p>

        <ul style={{ marginLeft: "1rem", marginBottom: "1rem" }}>

          <li>

            Withdrawals are only allowed if the account balance exceeds the

            initial investment.

          </li>

          <li>

            A formal request for withdrawal must be submitted [via email/portal]

            at least five (5) business days before the end of the quarter.

          </li>

          <li>

            No withdrawal fees shall be charged by KS Market; however, any bank

            or third-party fees shall be borne by the Investor.

          </li>

        </ul>

        <h3 style={{ fontWeight: "bold" }}>5. Performance Fee:</h3>

        <p>

          KS Market Ltd shall charge a performance fee of thirty per cent (30%)

          of the profits earned by the Investor. The fee shall be deducted from

          the profits before any distribution of profits to the Investor.

          Performance fees are calculated at the end of each quarter, upon the

          realisation of profits, and shall be applied prior to any profit

          withdrawal.

        </p>

        <h3 style={{ fontWeight: "bold" }}>

          6. Risk Exposure and Termination of Contract:

        </h3>

        <p>

          a. KS Market shall manage the overall risk exposure of the investment

          to a maximum of thirty per cent (30%) of equity. In the event that

          losses incurred reach or exceed this threshold, trading activities

          shall cease immediately.

        </p>

        <p>

          b. In the event trading is halted due to exceeding the loss threshold,

          KS Market will return the remaining capital to the Investor within

          thirty (30) business days.

        </p>

        <p>

          c. This Agreement shall automatically terminate if the conditions

          described in clause 6a occur, or at the end of the one-year term

          unless renewed by mutual agreement.

        </p>

        <h3 style={{ fontWeight: "bold" }}>7. KS Market Ltd Obligations:</h3>

        <p>

          a. KS Market shall diligently manage the investment in accordance with

          Hon's investment strategy and objectives.

        </p>

        <p>

          b. KS Market shall provide monthly reports to the Investor detailing

          the performance of the investment, including profit and loss

          statements and an analysis of risk exposure. Reports will be provided

          via [email/online portal].

        </p>

        <p>

          c. KS Market shall maintain appropriate risk management protocols to

          mitigate losses and preserve the Investor's capital to the extent

          possible.

        </p>

        <p>

          d. KS Market shall comply with all applicable laws, rules, and

          regulations governing financial services, fund management, and

          investment operations in Nigeria.

        </p>

        <h3 style={{ fontWeight: "bold" }}>

          8. Representations and Warranties:

        </h3>

        <p>

          a. KS Market represents and warrants that it has the necessary

          expertise, resources, and track record to manage the Investor's

          investment in accordance with the terms of this Agreement.

        </p>

        <p>b. The Investor represents and warrants that:</p>

        <ul style={{ marginLeft: "1rem", marginBottom: "1rem" }}>

          <li>

            i. They have reviewed and understood the terms of this Agreement.

          </li>

          <li>

            ii. They have the legal capacity and authority to enter into this

            Agreement.

          </li>

          <li>

            iii. They acknowledge the inherent risks of investing in financial

            markets, including the potential loss of capital, and have

            independently evaluated the investment.

          </li>

        </ul>

        <h3 style={{ fontWeight: "bold" }}>9. Confidentiality:</h3>

        <p>

          Both parties agree to maintain strict confidentiality with respect to

          any proprietary or confidential information disclosed during the term

          of this Agreement. This confidentiality obligation shall remain in

          effect during the term of this Agreement and for two (2) years

          following its termination, except as required by law or necessary for

          regulatory reporting.

        </p>

        <h3 style={{ fontWeight: "bold" }}>

          10. Governing Law and Jurisdiction:

        </h3>

        <p>

          This Agreement shall be governed by and construed in accordance with

          the laws of the Federal Republic of Nigeria. Any dispute arising out

          of or in connection with this Agreement shall be resolved through

          negotiation between the parties. If the dispute cannot be resolved

          amicably, it shall be submitted to binding arbitration in accordance

          with the Arbitration and Conciliation Act of Nigeria. The arbitration

          shall take place in [insert city], Nigeria, and the decision of the

          arbitrator(s) shall be final and binding.

        </p>

        <h3 style={{ fontWeight: "bold" }}>11. Force Majeure:</h3>

        <p>

          Neither party shall be held liable for any delay or failure to perform

          any part of this Agreement due to circumstances beyond its reasonable

          control, including but not limited to acts of God, natural disasters,

          war, government actions, or other unforeseen events (force majeure).

          In such cases, both parties will use their best efforts to mitigate

          the impact and resume normal operations as soon as possible.

        </p>

        <h3 style={{ fontWeight: "bold" }}>12. Indemnification:</h3>

        <p>

          The Investor agrees to indemnify and hold harmless KS Market Ltd, its

          officers, directors, and employees, from and against any claims,

          liabilities, or damages arising out of the Investor's breach of this

          Agreement or failure to adhere to any applicable laws and regulations.

        </p>

        <h3 style={{ fontWeight: "bold" }}>13. Amendments:</h3>

        <p>

          This Agreement may be amended or modified only by a written agreement

          signed by both parties. Any oral statements or representations made by

          either party shall not be binding or effective unless in writing and

          signed by both parties.

        </p>

        <h3 style={{ fontWeight: "bold" }}>14. Entire Agreement:</h3>

        <p>

          This Agreement constitutes the entire understanding between the

          parties with respect to the subject matter hereof and supersedes all

          prior negotiations, understandings, and agreements, whether oral or

          written. No waiver of any term of this Agreement shall be deemed a

          waiver of any other term or provision.

        </p>

        <h3 style={{ fontWeight: "bold" }}>15. Acknowledgment:</h3>

        <p>

          By submitting this form, the Investor acknowledges that they have

          read, understood, and agree to the terms of this Investment Agreement.

        </p>


{/* Read Terms Button */}
<a href="/userdetails">
            <button
              type="button"
              className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-gray-600 transition duration-200"
            >
              Proceed
            </button>
          </a>


      </div>
      

    </>

  );

};



export default Agreement;


