import React from "react";
import Slider from "react-slick";
import { ArrowRightIcon } from 'lucide-react';
import pythonImg from "./assets/code-1084923_640.png"
import forexImg from "./assets/forex-8207730_640.jpg"
import cryptoImg from "./assets/bitcoin-3132574_640.jpg"
import researchImg from "./assets/research-2535100_640.jpg"
import machineImg from "./assets/laptop-6332544_640.jpg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./education.css"; // You'll need to create this for the glass effect

const EducationCard = ({ title, description, imageUrl, ctaText, ctaLink }) => (
  <div className="glass-card p-6 mx-4">
    {imageUrl && (
      <img src={imageUrl} alt={title} className="w-full h-48 object-cover rounded-lg mb-4" />
    )}
    <h3 className="text-xl font-bold mb-2 text-white">{title}</h3>
    <p className="text-white text-base mt-2 text-left flex-grow">{description}</p>
    {ctaText && ctaLink && (
      <a
        href={ctaLink}
        className="mt-4 inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
      >
        {ctaText} <ArrowRightIcon className="ml-2" size={16} />
      </a>
    )}
  </div>
);

const EducationPage = () => {
  

  const courses = [
    {
      title: "Educational Programs",
      description: "At KSQuantitative, we provide industry-leading courses designed to equip you with the practical and theoretical knowledge to excel in modern financial markets. Our curriculum blends technology and finance, offering hands-on experience with tools used by professional traders and financial institutions.",
      imageUrl: null, // No image for this card
      ctaText: null,  // No button for this card
      ctaLink: null,  // No link for this card
    },
    {
      title: "Python for Finance",
      description: "Unlock the power of Python, one of the most essential programming languages for finance. This course focuses on applying Python to real-world financial problems, including data analysis, modeling, and backtesting trading strategies.",
      imageUrl: pythonImg,
      ctaText: "Learn More",
      ctaLink: "/python4finance"
    },
    {
      title: "Forex Trading with FXOpen FIX API and MT5 API",
      description: "Learn how to automate Forex trading using industry-standard tools like FIX API and MQL5. This course dives deep into building, testing, and deploying Forex algorithms with professional-level software.",
      imageUrl: forexImg,
      ctaText: "Coming Soon",
      ctaLink: "#/courses/forex-trading-api"
    },
    {
      title: "Crypto Trading with Algorithms",
      description: "The cryptocurrency market is evolving rapidly. In this course, you will learn how to build and optimize algorithmic trading systems tailored for crypto markets, from Bitcoin to altcoins.",
      imageUrl: cryptoImg,
      ctaText: "Coming Soon",
      ctaLink: "#/courses/crypto-algo-trading"
    },
    {
      title: "Quantitative Research",
      description: "This advanced course focuses on quantitative finance research methods. You'll learn how to conduct in-depth market analysis and develop strategies based on complex mathematical models.",
      imageUrl: researchImg,
      ctaText: "Coming Soon",
      ctaLink: "#/courses/quantitative-research"
    },
    {
      title: "Machine Learning and AI for Trading",
      description: "Leverage the power of AI and machine learning to build smarter trading algorithms. This course introduces machine learning models that can predict market movements and optimize trading strategies.",
      imageUrl: machineImg,
      ctaText: "Coming Soon",
      ctaLink: "#/courses/ml-ai-trading"
    }
  ];

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  // Next and Prev Arrows for custom buttons
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 p-2 rounded-full cursor-pointer hover:bg-blue-700 z-10"
        onClick={onClick}
      >
        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M10 18l6-6-6-6"></path>
        </svg>
      </div>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-blue-500 p-2 rounded-full cursor-pointer hover:bg-blue-700 z-10"
        onClick={onClick}
      >
        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M14 18l-6-6 6-6"></path>
        </svg>
      </div>
    );
  }

  return (
    <section
      className="relative h-screen w-full flex justify-center items-center overflow-hidden"
    >
      <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-center items-center">
        <div className="w-full max-w-6xl mx-auto">
          {/* Scrollable Education Cards */}
          <div className="w-full max-w-6xl mx-auto relative">
            <Slider {...sliderSettings}>
              {courses.map((course, index) => (
                <EducationCard
                  key={index}
                  title={course.title}
                  description={course.description}
                  imageUrl={course.imageUrl}
                  ctaText={course.ctaText}
                  ctaLink={course.ctaLink}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationPage;
