// Home.jsx
import React from "react";
import { ReactTyped } from "react-typed";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="text-center max-w-screen-md mx-auto">
        <h1 className="text-2xl md:text-4xl font-bold mb-6 text-white font-orbitron leading-relaxed">
          Innovative Quantitative Trading 
          <br />
          Solutions and Education Tailored for{" "}
          <br />
          <ReactTyped
            className="text-blue-400"
            strings={[
              "Investors.",
              "Crypto, Stocks, Forex Traders.",
              "Software Engineers.",
              "Data Scientists.",
              "Students.",
              "Financial institutions.",
              "Hedge funds.",
            ]}
            typeSpeed={150}
            backSpeed={50}
            loop
          />
        </h1>
        <p className="text-xl md:text-2xl mb-12 text-gray-200 font-orbitron tracking-wide">
          Your Gateway to Quantitative Finance and Algorithmic
          <br />
          Trading Excellence
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8">
          <Link
            to="/service"
            className="px-10 py-4 text-base lg:text-lg font-orbitron text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition-all duration-200 shadow-lg hover:scale-105"
          >
            Our Services
          </Link>
          <Link
            to="/about"
            className="px-10 py-4  text-base lg:text-lg font-orbitron text-blue-400 border-2 border-blue-400 rounded-lg hover:bg-blue-900/20 transition-all duration-200 shadow-lg hover:scale-105"
          >
            About Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;