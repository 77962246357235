import React, { useState, useEffect } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";
import { useNavigate } from "react-router-dom";

function UserDetails() {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    isAdult: false,
    fullName: "",
    phoneNumber: "",
    address: "",
    accountType: "Direct Fund Management",
    investmentExperience: "",
    bankAccountDetails: "",
    investmentAmount: "",
    currency: "₦", // Default currency
  });

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        const userEmail = user.email;
        setFormData((prevData) => ({
          ...prevData,
          emailAddress: userEmail,
        }));

        const fetchUserData = async () => {
          const userDocRef = doc(db, "Investors", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setFormData(userDocSnap.data());
            navigate('/userdash'); 
          }
        };
        fetchUserData();
      } else {
        navigate("/auth");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    } else if (!/^\d{11}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Invalid Phone Number (10 digits)";
      isValid = false;
    }
    if (!formData.isAdult) {
      newErrors.isAdult = "You must be at least 18 years old to proceed.";
      isValid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    // const currencyRegex = `(${formData.currency}\\s?)?`; // Dynamic regex

    if (!formData.investmentAmount.trim()) {
      newErrors.investmentAmount = "Investment Amount is required";
      isValid = false;
    } else if (
      !/^[0-9,]+(\.[0-9]{1,8})?$/.test(formData.investmentAmount) 
    ) {
      newErrors.investmentAmount = "Invalid Investment Amount format";
      isValid = false;
    }

    if (!formData.bankAccountDetails.trim()) {
      newErrors.bankAccountDetails = "Bank Account Details are required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionStatus("submitting");

    if (!validateForm()) {
      setSubmissionStatus("error");
      return;
    }

    try {
      const userDocRef = doc(db, "Investors", user.uid);

      // Sanitize investmentAmount
      const sanitizedAmount = formData.investmentAmount.replace(/[^0-9.]/g, "");
      const investmentAmount = parseFloat(sanitizedAmount);

      await setDoc(userDocRef, {
        ...formData,
        investmentAmount: investmentAmount,
        userId: user.uid,
        createdAt: serverTimestamp(),
      });
      setSubmissionStatus("success");
      navigate("/ConfettiCelebration");
    } catch (error) {
      console.error("Error adding document: ", error);
      setSubmissionStatus("error");
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        background: "rgba(255, 255, 255, 0.3)",
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
        borderRadius: "0.5rem",
        padding: "2rem",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "80%",
        margin: "0 auto",
        maxHeight: "80vh",
        overflowY: "auto",
        color: "whitesmoke",
        zIndex: 1,
        marginTop: "10vh",
      }}
    >
      {/* Success/Error Messages with Spinner */}
      {submissionStatus === "submitting" && (
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mr-2"></div>
          <div>Submitting...</div>
        </div>
      )}
      {submissionStatus === "success" && (
        <div className="text-green-500">Form submitted successfully!</div>
      )}
      {submissionStatus === "error" && (
        <div className="text-red-500">
          Error submitting form. Please try again.
        </div>
      )}

      <form onSubmit={handleSubmit} className="p-4">
        <h2 className="text-2xl font-bold mb-4 text-center">User Details</h2>
        <>
          {/* Read Terms Button */}
          <a href="/agreement">
            <button
              type="button"
              className="mt-4 w-full bg-gray-500 text-white py-2 rounded-md hover:bg-gray-600 transition duration-200"
            >
              Read Terms
            </button>
          </a>
          <div className="mb-4">
  <input 
    type="checkbox" 
    id="isAdult" 
    name="isAdult" 
    checked={formData.isAdult} 
    onChange={handleChange} 
  />
  <label htmlFor="isAdult" className="ml-2">
    Are you at least 18 years old?
  </label>
  {errors.isAdult && (
    <div className="text-red-500">{errors.isAdult}</div> 
  )}
</div>
          {/* Full Name */}
          <div className="mb-4">
            <label htmlFor="fullName" className="block text-sm font-medium">
              Full Name
            </label>
            <input
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
            {/* Display error message if present */}
            {errors.fullName && (
              <div className="text-red-500">{errors.fullName}</div>
            )}
          </div>

          {/* Phone Number */}
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-sm font-medium">
              Phone Number
            </label>
            <input
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && (
              <div className="text-red-500">{errors.phoneNumber}</div>
            )}
          </div>

          {/* Address */}
          <div className="mb-4">
            <label htmlFor="address" className="block text-sm font-medium">
              Address
            </label>
            <input
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            {errors.address && (
              <div className="text-red-500">{errors.address}</div>
            )}
          </div>

          {/* Account Type */}
          <div className="mb-4">
            <label htmlFor="accountType" className="block text-sm font-medium">
              Preferred Account Type
            </label>
            <select
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
              id="accountType"
              name="accountType"
              value={formData.accountType}
              onChange={handleChange}
            >
              <option value="Direct Fund Management">
                Direct Fund Management
              </option>
              <option value="Pooled Fund">Pooled Fund</option>
            </select>
          </div>

          {/* Investment Experience */}
          <div className="mb-4">
            <label
              htmlFor="investmentExperience"
              className="block text-sm font-medium"
            >
              Investment Experience
            </label>
            <select
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
              id="investmentExperience"
              name="investmentExperience"
              value={formData.investmentExperience}
              onChange={handleChange}
            >
              <option value="">None</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
          </div>

          {/* Currency Dropdown */}
          <div className="mb-4">
            <label htmlFor="currency" className="block text-sm font-medium">
              Currency
            </label>
            <select
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
              id="currency"
              name="currency"
              value={formData.currency}
              onChange={handleChange}
            >
              <option value="₦">₦ (Nigerian Naira)</option>
              <option value="$">$ (US Dollar)</option>
              <option value="€">€ (Euro)</option>
              <option value="£">£ (British Pound)</option>
              <option value="BTC">BTC (Bitcoin)</option>
              <option value="ETH">ETH (Ethereum)</option>
              <option value="USDT">USDT (Tether)</option>
              {/* Add more cryptocurrencies as needed */}
            </select>
          </div>

          {/* Investment Amount */}
          <div className="mb-4">
  <label htmlFor="investmentAmount" className="block text-sm font-medium">
    Investment Amount
  </label>
  <input 
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
    type="text"
    id="investmentAmount"
    name="investmentAmount"
    placeholder="Enter amount (e.g., 100, 5000, 0.5)" 
    value={formData.investmentAmount}
    onChange={(e) => {  // Input filtering logic here
      const input = e.target.value;
      const sanitizedInput = input.replace(/[^0-9,.]/g, ''); // Remove any non-numeric characters
      setFormData({
        ...formData,
        investmentAmount: sanitizedInput,
      });
    }}
  />
  {errors.investmentAmount && (
    <div className="text-red-500">{errors.investmentAmount}</div>
  )}
</div>

          {/* Bank Account Details */}
          <div className="mb-4">
            <label
              htmlFor="bankAccountDetails"
              className="block text-sm font-medium"
            >
              Bank Account Details
            </label>
            <textarea
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-2 focus:ring-blue-500 text-black"
              id="bankAccountDetails"
              name="bankAccountDetails"
              value={formData.bankAccountDetails}
              onChange={handleChange}
            />
            {errors.bankAccountDetails && (
              <div className="text-red-500">{errors.bankAccountDetails}</div>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200 flex items-center justify-center"
            disabled={submissionStatus === "submitting"}
          >
            {submissionStatus === "submitting" ? (
              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
            ) : (
              "Submit"
            )}
          </button>
        </>
      </form>
    </div>
  );
}

export default UserDetails;