// Registration.jsx
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { db } from "./firebase";
import { 
  collection, 
  addDoc, 
  serverTimestamp,
  query,
  where,
  getDocs 
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Registration() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    programFormat: "On-site training",
    paymentPlan: "No, one-time payment",
    trainingTime: "Morning",
    classDays: "Weekdays",
    expectations: [],
    recommendations: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const questions = [
    {
      text: "Welcome! Let's start with your full name",
      name: "name",
      type: "text",
      required: true,
    },
    {
      text: "What's your email address?",
      name: "emailAddress",
      type: "email",
      required: true,
    },
    {
      text: "And your phone number?",
      name: "phoneNumber",
      type: "tel",
      required: true,
    },
    {
      text: "Which program format would you prefer?",
      name: "programFormat",
      type: "select",
      required: true,
      options: [
        {
          value: "On-site training",
          label: "On-site training (physical location)",
        },
        {
          value: "Online training",
          label: "Online training (virtual classes)",
        },
      ],
    },
    {
      text: "What are your expectations from this program?",
      name: "expectations",
      type: "checkbox",
      required: true,
      options: [
        { 
          value: "Learn algorithmic trading",
          label: "Master algorithmic trading concepts and strategies" 
        },
        { 
          value: "Build AI trading systems",
          label: "Learn to build AI-powered trading systems" 
        },
        { 
          value: "Career transition",
          label: "Transition into quantitative trading career" 
        },
        { 
          value: "Fund management",
          label: "Manage live trading funds" 
        },
        { 
          value: "Network",
          label: "Network with like-minded traders" 
        },
      ],
    },
    {
      text: "When would you prefer to attend classes?",
      name: "trainingTime",
      type: "select",
      required: true,
      options: [
        { value: "Morning", label: "Morning (9 AM–12 PM)" },
        { value: "Afternoon", label: "Afternoon (1 PM–4 PM)" },
        { value: "Evening", label: "Evening (6 PM–9 PM)" },
      ],
    },
    {
      text: "Which days work best for you?",
      name: "classDays",
      type: "select",
      required: true,
      options: [
        { value: "Weekdays", label: "Weekdays" },
        { value: "Weekends", label: "Weekends" },
      ],
    },
    {
      text: "How would you like to handle the payment?",
      name: "paymentPlan",
      type: "select",
      required: true,
      options: [
        {
          value: "Yes, monthly installments",
          label: "Monthly installments",
        },
        {
          value: "Yes, two installments (50/50)",
          label: "Two installments (50/50)",
        },
        { value: "No, one-time payment", label: "One-time payment" },
      ],
    },
    {
      text: "Any recommendations or special requests for the program?",
      name: "recommendations",
      type: "textarea",
      required: false,
      placeholder: "Share your thoughts on how we can make this program even better for you..."
    },
  ];

  // Add this function in your Registration component
const handleNext = async () => {
    // Get current question details
    const currentField = questions[currentQuestion];
    const value = formData[currentField.name];
    
    // Validate current field
    const error = validateField(
      currentField.name, 
      value, 
      currentField.type
    );
    
    if (error) {
      // If there's an error, show it and don't proceed
      setErrors(prev => ({ ...prev, [currentField.name]: error }));
      return;
    }
  
    // If it's the email field, check for existing registration
    if (currentField.name === 'emailAddress') {
      try {
        const exists = await checkExistingRegistration(value);
        if (exists) {
          setErrors(prev => ({
            ...prev,
            emailAddress: "An application with this email already exists"
          }));
          return;
        }
      } catch (error) {
        console.error("Error checking email:", error);
      }
    }
  
    // Clear error for current field if validation passes
    setErrors(prev => ({ ...prev, [currentField.name]: '' }));
    
    // Move to next question
    setCurrentQuestion(prev => prev + 1);
  };

  const validateField = (name, value, type) => {
    switch (type) {
      case "text":
        if (!value || !value.trim()) {
          return "This field is required";
        }
        return "";

      case "email":
        if (!value || !value.trim()) {
          return "Email address is required";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return "Invalid email address format";
        }
        return "";

      case "tel":
        if (!value || !value.trim()) {
          return "Phone number is required";
        }
        const phoneRegex = /^\+?[\d\s-]{10,}$/;
        if (!phoneRegex.test(value.replace(/\s/g, ""))) {
          return "Please enter a valid phone number";
        }
        return "";

      case "checkbox":
        if (!value || value.length === 0) {
          return "Please select at least one option";
        }
        return "";

      case "select":
        if (!value) {
          return "Please select an option";
        }
        return "";

      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === "checkbox") {
      const updatedExpectations = checked
        ? [...formData.expectations, value]
        : formData.expectations.filter(item => item !== value);
      
      setFormData(prev => ({
        ...prev,
        expectations: updatedExpectations
      }));
      
      const error = validateField(name, updatedExpectations, type);
      setErrors(prev => ({ ...prev, [name]: error }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
      const error = validateField(name, value, type);
      setErrors(prev => ({ ...prev, [name]: error }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    questions.forEach(question => {
      if (question.required) {
        const error = validateField(
          question.name,
          formData[question.name],
          question.type
        );
        if (error) {
          newErrors[question.name] = error;
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };
  const checkExistingRegistration = async (email) => {
    try {
      const q = query(
        collection(db, "FormRegistration"), 
        where("emailAddress", "==", email)
      );
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking existing registration:", error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    setSubmissionStatus("submitting");
  
    try {
      const exists = await checkExistingRegistration(formData.emailAddress);
      if (exists) {
        setErrors(prev => ({
          ...prev,
          emailAddress: "An application with this email already exists"
        }));
        setSubmissionStatus("error");
        return;
      }

      const registrationData = {
        ...formData,
        createdAt: serverTimestamp(),
        status: 'pending',
        submittedAt: new Date().toISOString(),
        lastUpdated: serverTimestamp()
      };
  
      const questionnaireCollection = collection(db, "FormRegistration");
      const docRef = await addDoc(questionnaireCollection, registrationData);
      
      console.log("Registration submitted with ID: ", docRef.id);
      localStorage.setItem('registrationId', docRef.id);
      
      setSubmissionStatus("success");
      navigate("/thankyou");
      
    } catch (error) {
      console.error("Error submitting registration:", error);
      setSubmissionStatus("error");
      setErrors(prev => ({
        ...prev,
        submit: error.message || "Failed to submit registration. Please try again."
      }));
    }
  };

  const renderField = (question) => {
    const error = errors[question.name];
    
    const inputClasses = `w-full border ${
      error ? 'border-red-500' : 'border-gray-300'
    } px-4 py-3 rounded-lg text-black bg-white/90 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200`;

    switch (question.type) {
      case "text":
      case "email":
      case "tel":
        return (
          <div>
            <input
              type={question.type}
              name={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className={inputClasses}
              placeholder={`Enter your ${question.text.toLowerCase()}`}
            />
            {error && (
              <div className="text-red-500 text-sm mt-2">{error}</div>
            )}
          </div>
        );

      case "select":
        return (
          <div>
            <select
              name={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className={inputClasses}
            >
              {question.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {error && (
              <div className="text-red-500 text-sm mt-2">{error}</div>
            )}
          </div>
        );

      case "checkbox":
        return (
          <div>
            {question.options.map((option) => (
              <div key={option.value} className="flex items-center mb-3">
                <input
                  type="checkbox"
                  id={option.value}
                  name={question.name}
                  value={option.value}
                  checked={formData.expectations.includes(option.value)}
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label 
                  htmlFor={option.value} 
                  className="ml-3 text-sm font-medium text-white"
                >
                  {option.label}
                </label>
              </div>
            ))}
            {error && (
              <div className="text-red-500 text-sm mt-2">{error}</div>
            )}
          </div>
        );

      case "textarea":
        return (
          <div>
            <textarea
              name={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className={`${inputClasses} h-32 resize-none`}
              placeholder={question.placeholder}
            />
            {error && (
              <div className="text-red-500 text-sm mt-2">{error}</div>
            )}
          </div>
        );

      default:
        return null;
    }
  };
  return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="container mx-auto p-4 flex flex-col items-center justify-center min-h-screen"
      >
        <div className="w-full max-w-2xl bg-black/20 backdrop-blur-md rounded-xl p-8">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-2xl md:text-3xl font-bold mb-6 text-white font-orbitron text-center"
          >
            Join Our Program
          </motion.h1>

          <div className="mb-8">
            <div className="relative pt-1">
              <div className="flex justify-between items-center mb-4">
                <span className="text-white font-orbitron text-lg">
                  Step {currentQuestion + 1} of {questions.length}
                </span>
                <span className="text-blue-400 font-orbitron">
                  {Math.round(((currentQuestion + 1) / questions.length) * 100)}%
                </span>
              </div>
              <div className="relative h-2 bg-blue-900/30 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
                  className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-blue-400"
                />
              </div>
            </div>
          </div>

          <AnimatePresence mode="wait">
            <motion.div
              key={currentQuestion}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              className="mb-8"
            >
              <h2 className="text-xl font-medium mb-4 text-white font-orbitron">
                {questions[currentQuestion].text}
                {questions[currentQuestion].required && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </h2>
              {renderField(questions[currentQuestion])}
            </motion.div>
          </AnimatePresence>

          <motion.div className="flex justify-between mt-8">
            {currentQuestion > 0 && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setCurrentQuestion(prev => prev - 1)}
                className="px-6 py-3 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-200"
              >
                Previous
              </motion.button>
            )}
            
            {currentQuestion < questions.length - 1 ? (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={handleNext}  // Changed from the previous onClick
    className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 ml-auto"
  >
    Next
  </motion.button>
) : (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleSubmit}
                disabled={submissionStatus === "submitting"}
                className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 ml-auto font-orbitron"
              >
                {submissionStatus === "submitting" ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </span>
                ) : (
                  "Complete Registration"
                )}
              </motion.button>
            )}
          </motion.div>

          {submissionStatus === "error" && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-4 text-red-500 text-center bg-red-100/10 p-3 rounded-lg"
            >
              {errors.submit || "An error occurred. Please try again."}
            </motion.div>
          )}
        </div>
      </motion.div>
  );
}

export default Registration;