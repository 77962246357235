import React, { useState, useEffect, useCallback } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from "recharts";

const CustomTooltip = ({ active, payload, label, timeframe }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-gray-800 text-white p-3 rounded-lg shadow-md">
        <p className="label">{`${timeframe} ${label}`}</p>
        <p className="intro">{`Equity : $${payload[0].value.toLocaleString()}`}</p>
      </div>
    );
  }

  return null;
};

const barColors = [
  "#413ea0",
  "#00bfff",
  "#ff7f50",
  "#90ee90",
  "#ff1493",
  "#dda0dd",
]; // Example bar colors

const ForecastChart = ({ accountData, onAccountDataChange }) => {
  // Receive onAccountDataChange from parent
  const [mode, setMode] = useState("Compounding");
  const [timeframe, setTimeframe] = useState("Years");
  const [duration, setDuration] = useState(10);
  const [forecastData, setForecastData] = useState([]);

  const calculateForecast = useCallback(() => {
    if (
      !accountData ||
      accountData.equity === undefined ||
      accountData.monthly === undefined
    )
      return;

    const periods = duration;
    const rate = parseFloat(accountData.monthly) / 100;
    let equity = parseFloat(accountData.equity);
    const data = [];

    let adjustedRate = rate;
    if (timeframe === "Days") {
      adjustedRate = rate / 30;
    } else if (timeframe === "Years") {
      adjustedRate = rate * 12;
    }

    for (let i = 0; i <= periods; i++) {
      const calculatedEquity =
        mode === "Compounding"
          ? equity * Math.pow(1 + adjustedRate, i)
          : equity * (1 + adjustedRate * i);

      data.push({
        period: i,
        equity: calculatedEquity,
      });
    }

    setForecastData(data);
  }, [accountData, mode, timeframe, duration]);

  useEffect(() => {
    calculateForecast();
  }, [calculateForecast]);

  return (
    <div className="w-full bg-white/5 backdrop-blur-md rounded-xl p-6">
      <div className="mb-4">
        <h2 className="text-xl font-bold text-white">Investment Forecast</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Mode
          </label>
          <select
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Compounding">Compounding</option>
            <option value="Non-Compounding">Non-Compounding</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Target Period
          </label>
          <select
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value)}
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Days">Days</option>
            <option value="Months">Months</option>
            <option value="Years">Years</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Duration
          </label>
          <input
            type="number"
            value={duration}
            onChange={(e) =>
              setDuration(Math.max(1, parseInt(e.target.value) || 1))
            }
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Starting Amount ($)
          </label>
          <input
            type="number"
            value={
              accountData && accountData.equity !== undefined
                ? accountData.equity
                : ""
            }
            onChange={(e) => {
              const newEquity = e.target.value;
              onAccountDataChange({ ...accountData, equity: newEquity }); // Update parent's state
            }}
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Growth Rate (% per month)
          </label>
          <input
            type="number"
            value={
              accountData && accountData.monthly !== undefined
                ? accountData.monthly
                : ""
            }
            onChange={(e) => {
              const newMonthly = e.target.value;
              onAccountDataChange({ ...accountData, monthly: newMonthly }); // Update parent's state
            }}
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      <div className="h-96 mt-6">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={forecastData} margin={{ left: 40 }}>
            <defs>
              <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#6b7280" />
            <XAxis
              dataKey="period"
              stroke="#d1d5db"
              tickLine={false}
              axisLine={false}
              label={{
                value: timeframe,
                position: "insideBottom",
                offset: -5,
                fill: "#d1d5db",
              }}
            />
            <YAxis
              stroke="#d1d5db"
              tickLine={false}
              axisLine={false}
              label={{
                value: "",
                angle: -90,
                position: "insideLeft",
                style: { textAnchor: "middle" },
                fill: "#d1d5db",
              }}
              tickFormatter={(value) => `$${value.toLocaleString()}`}
              domain={[0, "auto"]}
            />
            <Tooltip content={<CustomTooltip timeframe={timeframe} />} />
            <ReferenceLine
              y={parseFloat(
                accountData && accountData.equity !== undefined
                  ? accountData.equity
                  : 0
              )}
              stroke="gray"
              strokeDasharray="3 3"
              label={{
                value: "Starting Equity",
                position: "top",
                fill: "gray",
              }}
            />
            <Bar dataKey="equity" barSize={20}>
              {forecastData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={barColors[index % barColors.length]}
                />
              ))}
            </Bar>
            <Line
              type="monotone"
              dataKey="equity"
              stroke="#8884d8"
              strokeWidth={2}
              dot={{ r: 1 }}
              activeDot={{ r: 6 }}
              fillOpacity={1}
              fill="url(#lineGradient)"
              isAnimationActive={true}
              animationDuration={1000}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-4 text-center text-sm text-white">
        <p>
          Final Growth in {duration}{" "}
          {timeframe === "Years" ? "Years" : timeframe}: $
          {forecastData[forecastData.length - 1]?.equity.toLocaleString()}
        </p>
        <p className="mt-1">
          {mode === "Compounding"
            ? "Results include compound interest effects"
            : "Results show linear growth without compounding"}
        </p>
      </div>
    </div>
  );
};

export default ForecastChart;