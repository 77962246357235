import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Blog Feed Component
const BlogFeed = ({ rssFeedUrl }) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(rssFeedUrl);
        const text = await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, 'text/xml');
        const items = Array.from(xml.querySelectorAll('item')).map(item => ({
          title: item.querySelector('title')?.textContent || 'Untitled',
          description: item.querySelector('description')?.textContent || 'No description',
          link: item.querySelector('link')?.textContent || '#',
        }));
        setPosts(items);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching RSS feed:', error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [rssFeedUrl]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (isLoading) return <div className="text-center py-4 text-white">Loading posts...</div>;
  if (error) return <div className="text-center text-red-500 py-4">Failed to load posts</div>;

  return (
    <div className="overflow-x-hidden"> {/* Add overflow-x-hidden to prevent horizontal scrollbar */}
      <Slider {...sliderSettings}>
        {posts.map((post, index) => (
          <div key={index} className="p-4 outline-none">
            <div className="bg-gray-800 shadow-lg rounded-lg overflow-hidden">
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2 text-white">{post.title}</h3>
                <p className="text-gray-300 text-base mt-2 text-left line-clamp-3">{post.description}</p>
                <a
                  href={post.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const MetaTraderWebsite = () => {
  const [language, setLanguage] = useState('en');
  const [selectedBrokers, setSelectedBrokers] = useState([]);

  // Predefined list of brokers
  const brokerList = [
    { id: 'broker1', name: 'Broker One', demo: 'Broker1-Demo', live: 'Broker1-Live' },
    { id: 'broker2', name: 'Broker Two', demo: 'Broker2-Demo', live: 'Broker2-Live' },
    { id: 'broker3', name: 'Broker Three', demo: 'Broker3-Demo', live: 'Broker3-Live' }
  ];

  // Languages supported by WebTerminal
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'ru', name: 'Russian' }
  ];

  // Generate WebTerminal iframe source
  const generateWebTerminalSrc = () => {
    const brokerParams = selectedBrokers.length > 0 
      ? `&servers=${selectedBrokers.map(b => `${b.demo},${b.live}`).join(',')}`
      : '&demo_all_servers=1';
    
    return `https://metatraderweb.app/trade?startup_mode=open_demo&startup_version=5&lang=${language}&save_password=off${brokerParams}&utm_campaign=mywebsite`;
  };

  return (
    <div className="min-h-screen bg-black text-white" style={{ padding: '20px' }}>
      <div className="max-w-6xl mx-auto px-4">
        <header className="bg-gray-900 text-white p-4 mb-6 rounded">
          <h1 className="text-2xl font-bold">Trading Platform Integration</h1>
          <p className="text-sm">Explore financial markets directly from our website</p>
        </header>

        <div className="grid md:grid-cols-3 gap-6 mb-6">
          <div className="col-span-2 bg-gray-900 shadow-lg rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">MetaTrader 5 WebTerminal</h2>
            <div className="flex mb-4 space-x-4 overflow-x-auto"> {/* Add overflow-x-auto for horizontal scrolling on small screens */}
              {/* Language Selector */}
              <select 
                value={language} 
                onChange={(e) => setLanguage(e.target.value)}
                className="p-2 border rounded bg-black text-white"
              >
                {languages.map(lang => (
                  <option key={lang.code} value={lang.code} className="bg-black">{lang.name}</option>
                ))}
              </select>

              {/* Broker Selection */}
              <div className="flex items-center space-x-2">
                <label className="font-medium">Select Brokers:</label>
                {brokerList.map(broker => (
                  <label key={broker.id} className="inline-flex items-center text-white">
                    <input
                      type="checkbox"
                      checked={selectedBrokers.some(b => b.id === broker.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedBrokers([...selectedBrokers, broker]);
                        } else {
                          setSelectedBrokers(selectedBrokers.filter(b => b.id !== broker.id));
                        }
                      }}
                      className="mr-1"
                    />
                    {broker.name}
                  </label>
                ))}
              </div>
            </div>

            {/* WebTerminal Iframe */}
            <div className="h-[600px] overflow-y-auto">
                <iframe 
                src={generateWebTerminalSrc()}
                title="MetaTrader 5 WebTerminal"
                allowFullScreen
                style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </div>
          </div>

          {/* Monetization Information Card */}
          <div className="bg-gray-900 shadow-lg rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Earn with Trading</h2>
            <div className="space-y-4">
              <p className="text-sm">
                Earn money by referring traders to brokers through our platform.
              </p>
              <div className="bg-gray-800 p-3 rounded">
                <h3 className="font-semibold mb-2">Monetization Strategies:</h3>
                <ul className="list-disc list-inside text-sm">
                  <li>Broker Lead Referrals</li>
                  <li>Affiliate Marketing</li>
                  <li>Custom Broker Partnerships</li>
                </ul>
              </div>
              <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300">
                Learn About Partnerships
              </button>
            </div>
          </div>
        </div>

        {/* Blog Feed Section */}
        <div className="bg-gray-900 shadow-lg rounded-lg p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Latest Trading Insights</h2>
          <BlogFeed 
            rssFeedUrl="https://example.com/trading-rss-feed" 
          />
        </div>

        <footer className="mt-6 text-center text-sm text-gray-500 pb-6">
          © {new Date().getFullYear()} Trading Platform. Not a financial advisor.
        </footer>
      </div>
    </div>
  );
};

export default MetaTraderWebsite;