// Auth.js
import React, { useState,useEffect } from 'react';
import { auth } from './firebase'; 
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    onAuthStateChanged
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; 
import { getFirestore, doc, getDoc, } from 'firebase/firestore';

const firestore = getFirestore();
function Auth() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); 

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                if (window.location.pathname === '/agreement') {
                    return; // Allow access to '/agreement' without checking Firestore
                  }
                // Check if user details exist in Firestore
                const userDocRef = doc(firestore, "Investors", user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    navigate('/userdash'); // Redirect to dashboard
                } else {
                    navigate('/userdetails'); // Redirect to user details form
                }
            }
        });
        return () => unsubscribe(); 
    }, [navigate]); 
       

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); 

        try {
            if (isLogin) {
                await signInWithEmailAndPassword(auth, email, password);
                navigate('/userdetails'); 
            } else {
                await createUserWithEmailAndPassword(auth, email, password);
                navigate('/userdetails'); 
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleGoogleSignIn = async () => {
        setError(null);
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            navigate('/userdetails'); 
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen ">
            <div className="bg-white p-8 rounded-lg shadow-md w-96"> 
                <h1 className="text-2xl font-bold mb-4 text-center">
                    {isLogin ? 'Sign In' : 'Sign Up'}
                </h1>
                {error && <div className="text-red-500 mb-4">{error}</div>} 
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div>
                        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email:</label>
                        <input 
                            type="email" 
                            id="email" 
                            value={email} 
                            onChange={handleEmailChange} 
                            required 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-gray-700 font-bold mb-2">Password:</label>
                        <input 
                            type="password" 
                            id="password" 
                            value={password} 
                            onChange={handlePasswordChange} 
                            required 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        />
                    </div>
                    <button 
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        {isLogin ? 'Sign In' : 'Sign Up'}
                    </button>
                </form>

                <button 
                    type="button" 
                    onClick={() => setIsLogin(!isLogin)} 
                    className="text-blue-500 hover:text-blue-700 font-bold mt-4"
                >
                    {isLogin ? 'Need to create an account?' : 'Already have an account?'}
                </button>

                <button 
                    type="button" 
                    onClick={handleGoogleSignIn}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
                >
                    Sign in with Google
                </button>
            </div>
        </div>
    );
}

export default Auth;