// App.js
import React from 'react';
import Home from './home'
import Navbar from './navbar';  // Your Navbar file
import AboutUs from './aboutus';
import Services from './service';
import ContactUs from './contact';
import EducationPage from './education';
import FundsPage from './funds';
import BlogApp from './BlogList';
import MyFxBookData from './myfxbook';
import Agreement from './agreement';
import Questionnaire from './componets/Questionnair';
import VideoBackground from './componets/backgroundvideo';
import UserDetails from './userdetails';
import ConfettiCelebration from './componets/ConfettiCelebration';
import Auth from './Auth';
import ClientDashboard from './userdash';
import { AuthProvider } from './Authcontext';
import SurveyThanks from './componets/thankyou';
import MetaTraderWebsite from './mt5';
import VantaLayout from './componets/vantalayout';
import CoursePage from './python4finance';
import Registration from './registration';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';


function App() {
  return (
    <Router>
       <AuthProvider> 
      <Navbar />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={
            <VantaLayout>
              <Home />
            </VantaLayout>
          } />
        <Route path="/about" element={
          <VantaLayout>
          <AboutUs />
        </VantaLayout>
          } />
        <Route path="/service" element={
          <VantaLayout>
          <Services />
        </VantaLayout>
      } />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/myfxbook" element={<MyFxBookData />} />
        <Route path="/registration" element={
          <VideoBackground>
          <Registration />
        </VideoBackground>
      } />
        <Route path="/python4finance" element={
        <VantaLayout> 
          <CoursePage />
        </VantaLayout>
      } />
        <Route path="/userdash" element={<ClientDashboard />} />
        <Route path="/education" element={
          <VantaLayout>
          <EducationPage />
          </VantaLayout>
        }/>
        <Route path="/mt5" element={<MetaTraderWebsite />} />
        <Route path="/ConfettiCelebration" element={<VideoBackground>
            <ConfettiCelebration />
          </VideoBackground>} />
          <Route path="/thankyou" element={<VideoBackground>
            <SurveyThanks />
          </VideoBackground>} />
        <Route path="/userdetails" element={
        <VideoBackground>
        <UserDetails />
      </VideoBackground>} />
        <Route path='/funds' element={
          <VantaLayout>
          <FundsPage />
          </VantaLayout>
        }/>
        <Route path='/blog' element={
          <VantaLayout>
          <BlogApp />
          </VantaLayout>
      } />
        <Route path="/agreement" element={
          <VideoBackground>
            <Agreement />
          </VideoBackground>
        } />
        <Route path="/Questionnair" element={
          <VideoBackground>
            <Questionnaire />
          </VideoBackground>
        } />
        <Route path="/auth" element={
  <VideoBackground>
    <Auth /> 
  </VideoBackground>
} />

      </Routes>
      <Analytics />
      </AuthProvider>
    </Router>
  );
}

export default App;
