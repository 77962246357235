// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./firebase"; 

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(app); 
 

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("currentUser updated in AuthProvider:", user);
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe; 
  }, [auth]);

  const value = {
    currentUser,
  };

  return (
    <AuthContext.Provider value={value}> 
      {!loading && children} 
    </AuthContext.Provider>
  );
};