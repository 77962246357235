// SignOut.js
import React from 'react';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function SignOut() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      console.log('Signing out...'); 
      await signOut(auth);
      console.log('Redirecting to home...'); 
      navigate('/'); 
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <button 
      onClick={handleSignOut}
      className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-24"
    >
      Sign Out
    </button>
  );
}

export default SignOut;