import React, { useEffect, useState, useCallback,memo } from "react";
import { motion } from "framer-motion";
import {
  getFirestore,
  doc,
  onSnapshot,
  collection,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "./Authcontext";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from "recharts";

// Constants
const CACHE_KEY = "myfxbook_data";
const CACHE_TIME = 60 * 60 * 1000; // 1 hour
const API_ENDPOINT = `https://www.myfxbook.com/api/get-my-accounts.json?session=${process.env.REACT_APP_MYFXBOOK_API_KEY}`;
const ACCOUNT_ID = 11164432; // Replace with your actual account ID

// Firestore references
const firestore = getFirestore();
const investorsCollection = collection(firestore, "Investors");

// Components
const StatCard = ({ title, value, icon, trend, color = "blue" }) => (
  <motion.div
    className="bg-white/15 backdrop-blur-md rounded-xl p-4 transition-all duration-300 hover:bg-white/20 hover:transform hover:scale-105 shadow-lg border border-white/10"
    whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
    whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
  >
    <div className="flex items-center justify-between mb-2">
      <motion.span
        className={`text-${color}-400 text-xl`}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
      >
        {icon}
      </motion.span>
      {trend && (
        <motion.span
          className={`text-sm ${trend > 0 ? "text-green-400" : "text-red-400"}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.2 } }}
        >
          {trend > 0 ? "↑" : "↓"} {Math.abs(trend).toFixed(2)}%
        </motion.span>
      )}
    </div>
    <h3 className="text-gray-400 text-sm font-medium">{title}</h3>
    <motion.p
      className="text-white text-lg font-bold mt-1"
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}
    >
      {value}
    </motion.p>
  </motion.div>
);

const CustomTooltip = ({ active, payload, label, timeframe }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-gray-800 text-white p-3 rounded-lg shadow-md">
        <p className="label">{`${timeframe} ${label}`}</p>
        <p className="intro">{`Equity: $${payload[0].value.toLocaleString()}`}</p>
      </div>
    );
  }
  return null;
};
// Chart configuration
const barColors = [
  "#413ea0",
  "#00bfff",
  "#ff7f50",
  "#90ee90",
  "#ff1493",
  "#dda0dd",
];

const ForecastChart = ({ accountData, initialEquity }) => {
  const [mode, setMode] = useState("Compounding");
  const [timeframe, setTimeframe] = useState("Years");
  const [duration, setDuration] = useState(10);
  const [forecastData, setForecastData] = useState([]);
  const [startingEquity, setStartingEquity] = useState(initialEquity);

  const calculateForecast = useCallback(() => {
    if (!accountData || accountData.monthly === undefined) return;

    const periods = duration;
    const rate = parseFloat(accountData.monthly) / 100;
    const equity = parseFloat(startingEquity);
    const data = [];

    const adjustedRate =
      timeframe === "Days"
        ? rate / 30
        : timeframe === "Years"
        ? rate * 12
        : rate; // Default to Months

    for (let i = 0; i <= periods; i++) {
      const calculatedEquity =
        mode === "Compounding"
          ? equity * Math.pow(1 + adjustedRate, i)
          : equity * (1 + adjustedRate * i);

      data.push({ period: i, equity: calculatedEquity });
    }

    setForecastData(data);
  }, [accountData, mode, timeframe, duration, startingEquity]);

  useEffect(() => {
    setStartingEquity(initialEquity);
  }, [initialEquity]);

  useEffect(() => {
    calculateForecast();
  }, [calculateForecast]);

  return (
    <div className="w-full bg-white/5 backdrop-blur-md rounded-xl p-6">
      <div className="mb-4">
        <h2 className="text-xl font-bold text-white">Investment Forecast</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Mode
          </label>
          <select
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Compounding">Compounding</option>
            <option value="Non-Compounding">Non-Compounding</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Target Period
          </label>
          <select
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value)}
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Days">Days</option>
            <option value="Months">Months</option>
            <option value="Years">Years</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Duration ({timeframe})
          </label>
          <input
            type="number"
            value={duration}
            onChange={(e) =>
              setDuration(Math.max(1, parseInt(e.target.value) || 1))
            }
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Starting Amount ($)
          </label>
          <input
            type="number"
            value={startingEquity}
            onChange={(e) => setStartingEquity(parseFloat(e.target.value))}
            className="w-full p-2 rounded bg-black text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      <div className="h-96 mt-6">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={forecastData} margin={{ left: 40 }}>
            <defs>
              <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#6b7280" />
            <XAxis
              dataKey="period"
              stroke="#d1d5db"
              tickLine={false}
              axisLine={false}
              label={{
                value: timeframe,
                position: "insideBottom",
                offset: -5,
                fill: "#d1d5db",
              }}
            />
            <YAxis
              stroke="#d1d5db"
              tickLine={false}
              axisLine={false}
              label={{
                value: "",
                angle: -90,
                position: "insideLeft",
                style: { textAnchor: "middle" },
                fill: "#d1d5db",
              }}
              tickFormatter={(value) => `$${value.toLocaleString()}`}
              domain={[0, "auto"]}
            />
            <Tooltip content={<CustomTooltip timeframe={timeframe} />} />
            <ReferenceLine
              y={parseFloat(startingEquity || 0)}
              stroke="gray"
              strokeDasharray="3 3"
              label={{
                value: "Starting Equity",
                position: "top",
                fill: "gray",
              }}
            />
            <Bar dataKey="equity" barSize={20}>
              {forecastData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={barColors[index % barColors.length]}
                />
              ))}
            </Bar>
            <Line
              type="monotone"
              dataKey="equity"
              stroke="#8884d8"
              strokeWidth={2}
              dot={{ r: 1 }}
              activeDot={{ r: 6 }}
              fillOpacity={1}
              fill="url(#lineGradient)"
              isAnimationActive={true}
              animationDuration={1000}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-4 text-center text-sm text-white">
        <p>
          Final Growth in {duration}{" "}
          {timeframe === "Years" ? "Years" : timeframe}: $
          {forecastData[forecastData.length - 1]?.equity.toLocaleString()}
        </p>
        <p className="mt-1">
          {mode === "Compounding"
            ? "Results include compound interest effects"
            : "Results show linear growth without compounding"}
        </p>
        <p className="mt-1">
          Growth Rate: {accountData?.monthly.toFixed(2)}% per month
        </p>
      </div>
    </div>
  );
};

// add funds
const AddFundsSection = () => {
  // WhatsApp number (replace with the admin's WhatsApp number)
  const whatsappNumber = "+2347026976893";
  const whatsappMessage = "Hello, I would like to add funds to my account.";

  // Construct the WhatsApp link
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    whatsappMessage
  )}`;

  return (
    <div className="bg-white/15 backdrop-blur-md rounded-xl p-6 transition-all duration-300 hover:bg-white/20 hover:transform hover:scale-105 shadow-lg border border-white/10 mb-6">
      <h2 className="text-xl font-bold text-white mb-4">
        Add Or Withdraw Funds
      </h2>
      <div>
        <p className="text-gray-300 mb-10">
          To add or Withdraw funds from your account, please contact the
          administrator via WhatsApp:
        </p>
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-2 px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
        >
          Contact Admin
        </a>
      </div>
    </div>
  );
};

const AccountSummary = memo(({ accountData, userDetails }) => {
  if (!accountData || !userDetails) return null;
  //console.log("AccountSummary:", { accountData, userDetails });

  const initialInvestment = parseFloat(userDetails.registeredAmount);
  const equity = parseFloat(userDetails.registeredAmount);
  const percentageChange =
    ((equity - parseFloat(userDetails.investmentAmount)) /
      parseFloat(userDetails.investmentAmount)) *
    100;

  const totalGain = !isNaN(percentageChange) ? percentageChange : 0;

  const drawdown = !isNaN(accountData.drawdown)
    ? (accountData.drawdown / 100) * (equity - initialInvestment) // Calculate drawdown based on the difference
    : 0;

  const dailyGrowth = !isNaN(accountData.daily)
    ? (accountData.daily / 100) * (equity - initialInvestment) // Calculate daily growth based on the difference
    : 0;

  const monthlyGrowth = !isNaN(accountData.monthly)
    ? (accountData.monthly / 100) * (equity - initialInvestment) // Calculate monthly growth based on the difference
    : 0;
    
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <StatCard
        title="Total Gain"
        value={`${totalGain.toFixed(2)}%`}
        icon="📈"
        trend={totalGain}
      />
      <StatCard
        title="Balance"
        value={`${
          userDetails.currency
        } ${userDetails.registeredAmount.toLocaleString()}`}
        icon="💰"
        color="green"
      />
      <StatCard
        title="Equity"
        value={`${userDetails.currency} ${equity.toLocaleString()}`}
        icon="💎"
        color="purple"
      />
      <StatCard
        title="Drawdown"
        value={`${userDetails.currency} ${drawdown.toFixed(2)}`}
        icon="📉"
        color="red"
        trend={-drawdown}
      />
      <StatCard
        title="Daily Growth"
        value={`${userDetails.currency} ${dailyGrowth.toFixed(2)}`}
        icon="📅"
        trend={dailyGrowth}
      />
      <StatCard
        title="Monthly Growth"
        value={`${userDetails.currency} ${monthlyGrowth.toFixed(2)}`}
        icon="📆"
        trend={monthlyGrowth}
      />
    </div>
  );
});

// ----------------------------------------------------------------------
// ClientDashboard Component
// ----------------------------------------------------------------------
const ClientDashboard = () => {
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [prevEquity, setPrevEquity] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const { currentUser } = useAuth();

  const [initialRegisteredAmount, setInitialRegisteredAmount] = useState(null);
  const refreshInterval = 5 * 60 * 1000; // 5 minutes

  // Fetch account data from MyFXBook API
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
          const { timestamp, data } = JSON.parse(cachedData);
          if (Date.now() - timestamp < CACHE_TIME) {
            const account = Array.isArray(data.accounts)
              ? data.accounts.find((account) => account.id === ACCOUNT_ID)
              : null;
            if (account) {
              setAccountData(account);
              setIsLoading(false);
              return;
            }
          }
        }

        const response = await fetch(API_ENDPOINT);
        if (!response.ok) {
          const message = `MyFXBook API request failed with status ${response.status}`;
          throw new Error(message);
        }

        const data = await response.json();
        const account = Array.isArray(data.accounts)
          ? data.accounts.find((account) => account.id === ACCOUNT_ID)
          : null;

        if (account) {
          setAccountData(account);
          localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({ timestamp: Date.now(), data })
          );
        } else {
          throw new Error("Account not found in MyFXBook API response");
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching account data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, refreshInterval);
    return () => clearInterval(intervalId);
  }, [refreshInterval]);

  // Fetch user data from Firestore
  useEffect(() => {
    if (!currentUser?.uid) return; // Check specifically for uid
  
    let isSubscribed = true;
    const userDocRef = doc(investorsCollection, currentUser.uid);
  
    const unsubscribe = onSnapshot(
      userDocRef,
      (doc) => {
        console.log("Firestore data fetched in ClientDashboard:", doc.data());
        if (doc.exists() && isSubscribed) {
          const data = doc.data();
          const registeredAmount = parseFloat(data.investmentAmount);
          
          // Only update if values have changed
          setUserDetails(prevDetails => {
            if (prevDetails?.registeredAmount === registeredAmount) {
              return prevDetails; // No change needed
            }
            return {
              ...data,
              registeredAmount,
              currency: data.currency,
            };
          });
  
          // Only set initial amount once
          setInitialRegisteredAmount(prev => {
            if (prev === null) {
              return registeredAmount;
            }
            return prev;
          });
        } else if (isSubscribed) {
          console.error("User document not found in Firestore");
        }
      },
      (error) => {
        if (isSubscribed) {
          console.error("Error fetching user data:", error);
          setError("Error fetching user data. Please try again later.");
        }
      }
    );
  
    return () => {
      isSubscribed = false;
      unsubscribe();
    };
  }, [currentUser?.uid]); // More specific dependency

  // Update client balance based on MyFXBook data changes
  useEffect(() => {
    // Memoize complex calculations to prevent unnecessary re-renders
    const calculateNewBalance = () => {
      if (
        !accountData?.equity ||
        prevEquity === null ||
        !userDetails?.registeredAmount ||
        initialRegisteredAmount === null ||
        !currentUser?.uid
      ) {
        return null;
      }
  
      const percentageChange = ((accountData.equity - prevEquity) / prevEquity) * 100;
      const balanceChange = initialRegisteredAmount * (percentageChange / 100);
      return userDetails.registeredAmount + balanceChange;
    };
  
    const newBalance = calculateNewBalance();
    
    // Only update if we have a valid new balance
    if (newBalance !== null) {
      const percentageChange = ((accountData.equity - prevEquity) / prevEquity) * 100;
  
      // Only update if change is significant
      if (Math.abs(percentageChange) >= 0.1) {
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          registeredAmount: newBalance,
        }));
  
        const userDocRef = doc(investorsCollection, currentUser.uid);
        updateDoc(userDocRef, { registeredAmount: newBalance }).catch((error) => {
          console.error("Error updating balance:", error);
        });
      }
    }
  
    // Always update prevEquity if accountData.equity exists
    if (accountData?.equity !== undefined) {
      setPrevEquity(accountData.equity);
    }
  }, [
    accountData?.equity,
    prevEquity,
    userDetails?.registeredAmount,
    initialRegisteredAmount,
    currentUser?.uid
  ]);
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  return (
    <div className="relative min-h-screen w-full overflow-hidden font-sans">
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      >
        <source src="/api/placeholder/400/320" type="video/webm" />
        <source
          src="https://res.cloudinary.com/adiec7/video/upload/v1728749526/3%E6%9C%8811%E6%97%A5_2_.mov.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Gradient Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black/80 via-black/70 to-black/80" />

      {/* Additional overlay for better text contrast */}
      <div className="absolute top-0 left-0 w-full h-full bg-black/40" />

      {/* Content */}
      <div className="relative z-10 h-screen overflow-y-auto mt-10">
        <div className="max-w-7xl mx-auto p-6 mb-24 mt-24">
          <div className="space-y-6 pb-24">
            {/* {user details} */}
            {/* Welcome Message */}
            {userDetails && (
              <div className="mb-6">
                <h1 className="text-2xl font-bold text-white">
                  Welcome, {userDetails.fullName}!
                </h1>
              </div>
            )}
            <div className="bg-white/15 backdrop-blur-md rounded-xl p-6 transition-all duration-300 hover:bg-white/20 hover:transform hover:scale-105 shadow-lg border border-white/10">
              <h2 className="text-xl font-bold text-white mb-4">
                Account Details
              </h2>
              {userDetails && (
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-gray-300">
                      <span className="text-white font-medium">Name:</span>{" "}
                      {userDetails.fullName}
                    </p>
                    <p className="text-gray-300">
                      <span className="text-white font-medium">
                        Invested Amount:
                      </span>{" "}
                      {userDetails.currency} {userDetails.investmentAmount}
                    </p>
                    <p className="text-gray-300">
                      <span className="text-white font-medium">
                        Date Invested:
                      </span>{" "}
                      {userDetails.createdAt?.toDate().toLocaleDateString()}
                    </p>
                  </div>
                  <div className="w-24 h-24 bg-gray-700 rounded-full flex items-center justify-center text-white font-bold text-2xl">
                    {/* Get User's Initials */}
                    {userDetails.fullName
                      .split(" ")
                      .map((n) => n[0])
                      .join("")
                      .toUpperCase()}
                  </div>
                </div>
              )}
            </div>
            {/* AddFundsSection Component */}
            <AddFundsSection />

            {/* Account Details Section (with progress bar) */}

            {/* Account Summary Section */}
            <AccountSummary
              accountData={accountData}
              userDetails={userDetails}
            />

            {/* Forecast Section */}
            <ForecastChart
              accountData={accountData}
              initialEquity={userDetails?.registeredAmount || 0} // Use userDetails here
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
