import React from 'react';
import BlogFeed from './blog';

function BlogApp() {
  const rssFeedUrl = 'https://medium.com/feed/@ksquants'; 

  

  return (
    <section
      className="relative h-auto min-h-screen w-full flex flex-col justify-center items-center overflow-hidden"
    >
      
        <div className="w-full max-w-6xl mx-auto"> {/* Added max-width for better alignment */}
          <BlogFeed rssFeedUrl={rssFeedUrl} />
        </div>
    
    </section>
  );
}

export default BlogApp;
