import React, { useState} from "react";
import Slider from "react-slick";
import { ArrowRightIcon } from 'lucide-react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./funds.css"; // You'll need to create this for the glass effect
import { Link } from "react-router-dom";

const FundCard = ({ title, description, onLearnMore, link }) => (
  <div className="glass-card p-6 mx-4">
    <h3 className="text-xl font-bold mb-2 text-white">{title}</h3>
    <p className="text-white text-base mt-2 text-left flex-grow">{description}</p>
    {link ? (
      <Link
        to={link}
        className="mt-4 inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
      >
        Join Us <ArrowRightIcon className="ml-2" size={16} />
      </Link>
    ) : (
      <button
        onClick={onLearnMore}
        className="mt-4 inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
      >
        Learn More <ArrowRightIcon className="ml-2" size={16} />
      </button>
    )}
  </div>
);

const PopUp = ({ isOpen, onClose, title, description, points, onJoin }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md">
        <h2 className="text-2xl font-bold mb-4">{title}</h2>
        <p className="mb-4">{description}</p>
        {points && points.length > 0 ? (
          <ul className="list-disc pl-5 mb-4">
            {points.map((point, index) => (
              <li key={index} className="mb-2">{point}</li>
            ))}
          </ul>
        ) : null}
        <div className="flex justify-end">
          {onJoin && (
            <Link
              to='/contact'
              onClick={onJoin}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Join Now
            </Link>
          )}
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const FundsPage = () => {
  
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [selectedFund, setSelectedFund] = useState(null);


  const funds = [
    {
      title: "Hon Fund Management: Tailored Financial Solutions",
      description: "Hon Fund Management provides a diverse selection of investment funds tailored to different financial goals and risk profiles. Our funds use advanced quantitative strategies and technology to maximize returns.",
      points: []
    },
    {
      title: "Direct Fund Management – Personalized and Strategic",
      description: "For those who want a tailored approach, we manage your portfolio directly from your account, using advanced, data-driven algorithms to maximize returns. With a minimum account size of $10,000, this exclusive service puts you in full control of your funds while benefiting from our expertise in high-performance trading strategies.",
      points: [
        "Stay in control of your capital while we handle the trades.",
        "Gain access to our proprietary strategies, tested to perform in diverse market conditions.",
        "Enjoy peace of mind with real-time monitoring and transparent reporting.",
        "Achieve annual returns ranging from 50% to 100%"
      ]
    },
    {
      title: "Pooled Fund – Affordable & Accessible for All Investors",
      description: "For a more affordable entry point, you can join our Pooled Investment Fund with a contribution of just $350. This collective fund allows you to pool resources with other investors, giving you access to our high-performance trading strategies while minimizing risk and maximizing your potential returns.",
      points: [
        "Low barrier to entry with professional fund management.",
        "Diversified portfolio to mitigate risks and optimize growth.",
        "Ideal for those looking to start small and grow their investment.",
        "Achieve annual returns ranging from 50% to 100%"
      ]
    },
    {
      title: "Important Information",
      description: "All investments involve inherent risks, including the possible loss of principal. There is no assurance that any strategy will achieve its objectives or meet performance expectations. Past performance is not indicative of future results.",
      points: [
        // No points for the last card
      ],
      link: '/agreement' 
    }
  ];

  const handleLearnMore = (fund) => {
    if (fund.title === "Hon Fund Management: Tailored Financial Solutions") {
      // Add the additional description for "Hon Fund Management"
      fund.description += " At the core is Hon, our proprietary algorithm that employs AI and machine learning for precise, data-driven Forex trading, including oil (WTI) and NZD/JPY. Hon dynamically adjusts to real-time market conditions, ensuring optimal performance";
    }
    setSelectedFund(fund);
    setPopUpOpen(true);
  };

  const handleJoin = () => {
    console.log("Joining fund:", selectedFund.title);
    setPopUpOpen(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 p-2 rounded-full cursor-pointer hover:bg-blue-700 z-10"
        onClick={onClick}
      >
        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M10 18l6-6-6-6"></path>
        </svg>
      </div>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-blue-500 p-2 rounded-full cursor-pointer hover:bg-blue-700 z-10"
        onClick={onClick}
      >
        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M14 18l-6-6 6-6"></path>
        </svg>
      </div>
    );
  }

  return (
    <section
      className="relative h-screen w-full flex justify-center items-center overflow-hidden"
    >
      <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-center items-center">
        <div className="w-full max-w-6xl mx-auto">
          <div className="w-full max-w-6xl mx-auto relative">
            <Slider {...sliderSettings}>
              {funds.map((fund, index) => (
                <FundCard
                  key={index}
                  title={fund.title}
                  description={fund.description}
                  onLearnMore={() => handleLearnMore(fund)}
                  link={fund.link} // Pass the link prop to FundCard
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <PopUp
        isOpen={popUpOpen}
        onClose={() => setPopUpOpen(false)}
        title={selectedFund?.title}
        description={selectedFund?.description}
        points={selectedFund?.points}
        onJoin={selectedFund?.link ? handleJoin : null}
      />
    </section>
  );
};

export default FundsPage;
