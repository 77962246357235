import React, { useEffect } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom'; 

const ConfettiCelebration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/userdash');
    }, 9000); // 9000 milliseconds = 9 seconds

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, [navigate]);

  return (
    <>
      <Confetti width={window.innerWidth} height={window.innerHeight} />
      <div
        style={{
          background: "rgba(255, 255, 255, 0.3)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          borderRadius: "0.5rem",
          padding: "2rem",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "80%",
          margin: "0 auto",
          color: "whitesmoke",
          textAlign: "center",
          marginTop: "10vh",
        }}
      >
        <h2
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          Thank you for joining us! <br/> We're excited to have you here!
        </h2>
        <p>We will review your application and contact you soon.</p>
      </div>
    </>
  );
};

export default ConfettiCelebration;