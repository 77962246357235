// VantaBackground.jsx
import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import GLOBE from "vanta/dist/vanta.globe.min";

const VantaBackground = ({ 
  color = 0x0d47a1,
  backgroundColor = 0x000000,
  scale = 1.4,
  scaleMobile = 1.0
}) => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect = null;

    if (vantaRef.current) {
      vantaEffect = GLOBE({
        el: vantaRef.current,
        THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        scale,
        scaleMobile,
        color,
        backgroundColor,
      });
    }

    return () => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };
  }, [color, backgroundColor, scale, scaleMobile]);

  return (
    <div 
      ref={vantaRef}
      className="absolute top-0 left-0 w-full h-full"
    />
  );
};

export default VantaBackground;