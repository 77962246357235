import React, { useState} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Questionnaire() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    tradingExperience: "Beginner",
    priorCourses: "No",
    courseGoals: [],
    programFormat: "On-site training",
    trainingLocation: "Central business district",
    programDuration: "4 months",
    expectedBudget: "Less than $5,000",
    paymentPlan: "No, one-time payment",
    earlyBirdInterest: "No",
    learningEnvironment: "Formal office setting",
    teamPreference: "Teams",
    aiComfort: "Yes",
    trainingTime: "Morning",
    classDays: "Weekdays",
    additionalFeatures: "",
    otherFeedback: "",
  });

  
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const questions = [
    {
      text: "Name",
      name: "name",
      type: "text",
      required: true,
    },
    {
      text: "Email Address",
      name: "emailAddress",
      type: "email",
      required: true,
    },
    {
      text: "Phone Number",
      name: "phoneNumber",
      type: "tel",
      required: true,
    },
    {
      text: "What is your current level of experience in trading?",
      name: "tradingExperience",
      type: "select",
      required: true,
      options: [
        { value: "Beginner", label: "Beginner" },
        { value: "Intermediate", label: "Intermediate" },
        { value: "Advanced", label: "Advanced" },
      ],
    },
    {
      text: "Have you previously taken any courses in algorithmic trading, Python, or AI?",
      name: "priorCourses",
      type: "select",
      required: true,
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
    {
      text: "What are your primary goals for joining this program? (Select all that apply)",
      name: "courseGoals",
      type: "checkbox",
      required: true,
      options: [
        {
          value: "Learn Python for trading",
          label: "Learn Python for trading",
        },
        {
          value: "Develop AI-powered trading strategies",
          label: "Develop AI-powered trading strategies",
        },
        { value: "Manage live funds", label: "Manage live funds" },
        {
          value: "Start a career in fund management",
          label: "Start a career in fund management",
        },
      ],
    },
    {
      text: "Which program format do you prefer?",
      name: "programFormat",
      type: "select",
      required: true,
      options: [
        {
          value: "On-site training",
          label: "On-site training (physical location)",
        },
        {
          value: "Online training",
          label: "Online training (virtual classes)",
        },
        {
          value: "Hybrid training",
          label: "Hybrid training (combination of online and on-site)",
        },
      ],
    },
    {
      text: 'If you selected "on-site" or "hybrid," where would you prefer the physical training location to be?',
      name: "trainingLocation",
      type: "select",
      required: true,
      options: [
        {
          value: "Central business district",
          label: "Central business district",
        },
        { value: "Suburban area", label: "Suburban area" },
      ],
    },
    {
      text: "What is your preferred duration for the program?",
      name: "programDuration",
      type: "select",
      required: true,
      options: [
        { value: "4 months", label: "4 months" },
        { value: "6 months", label: "6 months" },
      ],
    },
    {
      text: "What is your expected budget for a 4–6-month comprehensive training program?",
      name: "expectedBudget",
      type: "select",
      required: true,
      options: [
        { value: "Less than $2,000", label: "Less than $2,000" },
        { value: "$3,000–$5,500", label: "$3,000–$5,500" },
        { value: "$5,500–$10,000", label: "$5,500–$10,000" },
        { value: "More than $10,000", label: "More than $10,000" },
      ],
    },
    {
      text: "Would you prefer a payment plan?",
      name: "paymentPlan",
      type: "select",
      required: true,
      options: [
        {
          value: "Yes, monthly installments",
          label: "Yes, monthly installments",
        },
        {
          value: "Yes, two installments (50/50)",
          label: "Yes, two installments (50/50)",
        },
        { value: "No, one-time payment", label: "No, one-time payment" },
      ],
    },
    {
      text: "Would you be interested in early bird discounts or group pricing options?",
      name: "earlyBirdInterest",
      type: "select",
      required: true,
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
    },
    {
      text: "What type of learning environment do you find most effective?",
      name: "learningEnvironment",
      type: "select",
      required: true,
      options: [
        { value: "Formal office setting", label: "Formal office setting" },
        {
          value: "Flexible, casual setting",
          label: "Flexible, casual setting (e.g., apartment-based)",
        },
        {
          value: "Online learning from home",
          label: "Online learning from home",
        },
      ],
    },
    {
      text: "Do you prefer working in teams or independently during training?",
      name: "teamPreference",
      type: "select",
      required: true,
      options: [
        { value: "Teams", label: "Teams" },
        { value: "Independently", label: "Independently" },
        { value: "Both", label: "Both" },
      ],
    },
    {
      text: "Are you comfortable using AI and programming tools as part of your learning experience?",
      name: "aiComfort",
      type: "select",
      required: true,
      options: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
        { value: "Not sure", label: "Not sure" },
      ],
    },
    {
      text: "What time of the day would be most convenient for your training sessions?",
      name: "trainingTime",
      type: "select",
      required: true,
      options: [
        { value: "Morning", label: "Morning (9 AM–12 PM)" },
        { value: "Afternoon", label: "Afternoon (1 PM–4 PM)" },
        { value: "Evening", label: "Evening (6 PM–9 PM)" },
      ],
    },
    {
      text: "Do you prefer weekday or weekend classes?",
      name: "classDays",
      type: "select",
      required: true,
      options: [
        { value: "Weekdays", label: "Weekdays" },
        { value: "Weekends", label: "Weekends" },
        { value: "Both", label: "Both" },
      ],
    },
    {
      text: "What features or benefits would you like us to include in the program to make it more valuable to you?",
      name: "additionalFeatures",
      type: "textarea",
      required: false,
    },
    {
      text: "Is there anything else you'd like to share that would help us design a program that meets your expectations?",
      name: "otherFeedback",
      type: "textarea",
      required: false,
    },
  ];


  const validateQuestion = (questionName, value, type, required) => {
    if (!required && (!value || value.length === 0)) {
      return "";
    }

    switch (type) {
      case "text":
        if (!value || !value.trim()) {
          return "This field is required";
        }
        return "";

      case "email":
        if (!value || !value.trim()) {
          return "Email address is required";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return "Invalid email address format";
        }
        return "";

      case "tel":
        if (!value || !value.trim()) {
          return "Phone number is required";
        }
        const phoneRegex = /^\+?[\d\s-]{10,}$/;
        if (!phoneRegex.test(value.replace(/\s/g, ""))) {
          return "Please enter a valid phone number";
        }
        return "";

      case "checkbox":
        if (required && (!value || value.length === 0)) {
          return "Please select at least one option";
        }
        return "";

      case "select":
        if (required && !value) {
          return "Please select an option";
        }
        return "";

      case "textarea":
        if (required && (!value || !value.trim())) {
          return "This field is required";
        }
        return "";

      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const question = questions.find(q => q.name === name);

    if (type === "checkbox") {
      const updatedGoals = formData.courseGoals.includes(value)
        ? formData.courseGoals.filter((goal) => goal !== value)
        : [...formData.courseGoals, value];

      setFormData(prev => ({ ...prev, courseGoals: updatedGoals }));
      
      const error = validateQuestion(name, updatedGoals, type, question.required);
      setErrors(prev => ({ ...prev, [name]: error }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
      
      const error = validateQuestion(name, value, type, question.required);
      setErrors(prev => ({ ...prev, [name]: error }));
    }
  };

  const validateCurrentQuestion = () => {
    const question = questions[currentQuestion];
    const value = formData[question.name];
    const error = validateQuestion(question.name, value, question.type, question.required);
    
    setErrors(prev => ({
      ...prev,
      [question.name]: error
    }));

    return !error;
  };

  const handleNext = () => {
    if (validateCurrentQuestion()) { // Validate only the current question
      setCurrentQuestion(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentQuestion(prev => Math.max(0, prev - 1));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    questions.forEach(question => {
      const value = formData[question.name];
      const error = validateQuestion(question.name, value, question.type, question.required);
      
      if (error) {
        newErrors[question.name] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      const firstErrorIndex = questions.findIndex(q => errors[q.name]);
      if (firstErrorIndex !== -1) {
        setCurrentQuestion(firstErrorIndex);
      }
      return;
    }

    setSubmissionStatus("submitting");

    try {
      const questionnairesCollection = collection(db, "Questionnaire");
      await addDoc(questionnairesCollection, {
        ...formData,
        createdAt: serverTimestamp(),
      });
      setSubmissionStatus("success");
      navigate("/thankyou");
    } catch (error) {
      console.error("Error adding document: ", error);
      setSubmissionStatus("error");
    }
  };

  const questionVariants = {
    hidden: { opacity: 0, x: "-100%" },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, x: "100%", transition: { duration: 0.5 } },
  };

  const renderField = (question) => {
    const error = errors[question.name];
    
    const inputClasses = `w-full border ${
      error ? 'border-red-500' : 'border-gray-300'
    } px-3 py-2 rounded text-black`;

    switch (question.type) {
      case "text":
      case "email":
      case "tel":
        return (
          <div>
            <input
              type={question.type}
              name={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className={inputClasses}
            />
            {error && (
              <div className="text-red-500 text-sm mt-1">{error}</div>
            )}
          </div>
        );

      case "select":
        return (
          <div>
            <select
              name={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className={inputClasses}
            >
              {question.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {error && (
              <div className="text-red-500 text-sm mt-1">{error}</div>
            )}
          </div>
        );

      case "checkbox":
        return (
          <div>
            {question.options.map((option) => (
              <div key={option.value} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={option.value}
                  name={question.name}
                  value={option.value}
                  checked={formData[question.name].includes(option.value)}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label htmlFor={option.value} className="text-white">
                  {option.label}
                </label>
              </div>
            ))}
            {error && (
              <div className="text-red-500 text-sm mt-1">{error}</div>
            )}
          </div>
        );

      case "textarea":
        return (
          <div>
            <textarea
              name={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className={`${inputClasses} h-32`}
            />
            {error && (
              <div className="text-red-500 text-sm mt-1">{error}</div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto p-4 flex flex-col items-center justify-center min-h-screen"
    >
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-white">
        Algorithmic Trading Training Program Survey
      </h1>

      <div className="w-full max-w-md">
        <div className="mb-4 text-white text-sm">
          Question {currentQuestion + 1} of {questions.length}
        </div>

        <AnimatePresence mode="popLayout">
          {questions.map((question, index) => (
            <motion.div
              key={index}
              variants={questionVariants}
              initial="hidden"
              animate={currentQuestion === index ? "visible" : "hidden"}
              exit="exit"
              className={`mb-4 w-full p-4 bg-gray-800 rounded-lg shadow-md text-white ${
                currentQuestion === index ? "" : "hidden"
              }`}
            >
              <h2 className="text-lg font-medium mb-2">
                {question.text}
                {question.required && <span className="text-red-500 ml-1">*</span>}
              </h2>
              {renderField(question)}
            </motion.div>
          ))}
        </AnimatePresence>

        <div className="flex justify-between mt-4">
          {currentQuestion > 0 && (
            <button
              onClick={handlePrevious}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
            >
              Previous
            </button>
          )}
          
          {currentQuestion === questions.length - 1 ? (
            <button
              onClick={handleSubmit}
              disabled={submissionStatus === "submitting"}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-auto"
            >
              {submissionStatus === "submitting" ? "Submitting..." : "Submit"}
            </button>
          ) : (
            <button
              onClick={handleNext}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r ml-auto"
            >
              Next
            </button>
          )}
        </div>

        {submissionStatus === "error" && (
          <div className="mt-4 text-red-500 text-center">
            An error occurred. Please try again.
          </div>
        )}

        {Object.keys(errors).length > 0 && currentQuestion === questions.length - 1 && (
          <div className="mt-4 text-red-500 text-sm">
            <p></p>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default Questionnaire;