import React from 'react';

const VideoBackground = ({ children }) => (
  <div className="relative min-h-screen w-full overflow-hidden font-sans">
    {/* Video Background */}
    <video
      className="absolute top-0 left-0 w-full h-full object-cover"
      autoPlay
      loop
      muted
      playsInline
      preload="auto"
    >
      <source src="/api/placeholder/400/320" type="video/webm" />
      <source
        src="https://res.cloudinary.com/adiec7/video/upload/v1728749526/3%E6%9C%8811%E6%97%A5_2_.mov.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>

    {/* Gradient Overlay */}
    <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black/80 via-black/70 to-black/80" />
    {/* Additional overlay for better text contrast */}
    <div className="absolute top-0 left-0 w-full h-full bg-black/40" />

    {/* Content Container */}
    <div className="relative z-20 h-screen overflow-y-auto mt-10">
      {/* Your content goes here */}
      {children}
    </div>
  </div>
);

export default VideoBackground;