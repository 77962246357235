import React, { useState, useEffect} from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useNavigate } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaInstagram, } from 'react-icons/fa'; // Import social icons
import './Navbar.css';  
import SignOut from './signout';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
const firestore = getFirestore();


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Check if user details exist in Firestore, BUT ONLY IF NOT ON /agreement
        if (window.location.pathname !== '/agreement') { 
          const userDocRef = doc(firestore, "Investors", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setUser(user);
          } else {
            navigate('/userdetails');
          }
        } else { 
          setUser(user); // Set the user even if details are not in Firestore
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [navigate]);


  const menuItems = [
    { to: '/', label: 'Home' },
    { to: '/service', label: 'Services' },
    { to: '/about', label: 'About Us' },
    { to: '/contact', label: 'Contact' },
    { to: '/education', label: 'Courses' },
    {to: '/funds', label: 'Join Our Fund'},
    {to: '/blog', label: 'Blog'},
    {to: '/myfxbook', label: 'Track Our Fund'},
    user 
    ? { to: '/userdash', label: 'Dashboard' } 
    : { to: '/auth', label: 'Sign In | Up' },
  ];

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '20px',
      top: '20px',
    },
    bmBurgerBars: {
      background: '#ffffff',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: '250px',
      right: '0px',
    },
    bmMenu: {
      background: '#ffffff',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
    },
    bmItemList: {
      color: '#0d47a1',
      padding: '0.8em',
    },
    bmItem: {
      display: 'block',
      margin: '5px 0',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  };

  return (
    <nav className="fixed w-full top-0 z-50 flex items-center justify-between p-4 mb-4 text-white">
      <Link to="/" className="text-lg font-bold hover:text-indigo-200 transition-colors duration-200 font-orbitron">
        KSQuantitative
      </Link>

    
        

      <Menu 
        right
        isOpen={isOpen}
        onStateChange={handleStateChange}
        styles={styles}
      >
        {user && ( // Conditionally render the welcome message
          <span className="mr-4">
            Hi, {user.displayName?.slice(0, 5)}! 
          </span>
        )}
        {/* Navigation Links */}
        {menuItems.map((item) => (
          <Link 
            key={item.to} 
            to={item.to} 
            className="menu-item text-indigo-700 hover:text-indigo-500 transition-colors duration-200 overflow-x-auto"
            onClick={closeMenu}
          >
            {item.label}
          </Link>
        ))}

        {/* Social Media Icons - Flexbox for horizontal alignment */}
        <div className="social-icons mt-2 flex-row">
          <a href="https://www.linkedin.com/company/ksmarket/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3Bp1eWHZcySReL3DYAtgtTGg%3D%3D" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <FaLinkedin size={24} />
          </a>
          <a href="https://www.instagram.com/ksquants" target="_blank" rel="noopener noreferrer" className="hover:text-pink-500">
            <FaInstagram size={24} />
          </a>
          <a href="#https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
            <FaTwitter size={24} />
          </a>
        </div>
        <SignOut /> 
      </Menu>
    </nav>
  );
};

export default Navbar;
