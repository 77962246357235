import React from 'react';
import Confetti from 'react-confetti';

const SurveyThanks = () => {
  

  return (
    <>
      <Confetti width={window.innerWidth} height={window.innerHeight} />
      <div
        style={{
          background: "rgba(255, 255, 255, 0.3)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          borderRadius: "0.5rem",
          padding: "2rem",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "80%",
          margin: "0 auto",
          color: "whitesmoke",
          textAlign: "center",
          marginTop: "30vh",
        }}
      >
        <p>Thank you for joining us! <br/> We're excited to have you here!</p>
        <p>We will review your application and contact you soon.</p>
      </div>
    </>
  );
};

export default SurveyThanks;