// CoursePage.jsx
import React from "react";
import { Link } from "react-router-dom";

const CoursePage = () => {
  return (
      <main className="pt-20 h-screen overflow-y-auto mb-20"
      style={{ overflowY: "auto"}}>
        {/* Hero Section */}
        <section className="px-4 py-16 md:py-24"
        style={{ overflowY: "auto" }}>
          <div className="max-w-6xl mx-auto text-center">
            <h1 className="text-3xl md:text-5xl font-bold text-white font-orbitron mb-6">
              Your Path to{" "}
              <span className="text-blue-400">Algorithmic Trading</span> Mastery
            </h1>
            <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto">
              Master AI-Powered Trading Strategies & Fund Management
            </p>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="px-4 py-16 bg-black/20">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-2xl md:text-4xl font-orbitron text-center text-white mb-12">
              Why Choose Our Program?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {benefits.map((benefit, index) => (
                <div 
                  key={index}
                  className="bg-black/30 backdrop-blur-md p-6 rounded-lg border border-blue-500/20"
                >
                  <h3 className="text-xl font-orbitron text-blue-400 mb-3">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-300">
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section className="px-4 py-16">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-2xl md:text-4xl font-orbitron text-center text-white mb-12">
              Payment Plans
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              {/* Onsite Training */}
              <div className="bg-black/30 backdrop-blur-md p-8 rounded-lg border border-blue-500/30">
                <h3 className="text-2xl font-orbitron text-blue-400 mb-6">Onsite Training</h3>
                <div className="mb-6">
                  <p className="text-gray-400 line-through mb-2">$950</p>
                  <p className="text-3xl text-white font-bold">$600</p>
                  <p className="text-blue-400 text-sm">Early Bird Discount</p>
                </div>
                <ul className="text-gray-300 space-y-3 mb-6">
                  <li>• Direct mentorship access</li>
                  <li>• Hands-on practical sessions</li>
                  <li>• Networking opportunities</li>
                </ul>
              </div>

              {/* Online Training */}
              <div className="bg-black/30 backdrop-blur-md p-8 rounded-lg border border-blue-500/30">
                <h3 className="text-2xl font-orbitron text-blue-400 mb-6">Online Training</h3>
                <div className="mb-6">
                  <p className="text-gray-400 line-through mb-2">$600</p>
                  <p className="text-3xl text-white font-bold">$300</p>
                  <p className="text-blue-400 text-sm">Early Bird Discount</p>
                </div>
                <ul className="text-gray-300 space-y-3 mb-6">
                  <li>• Flexible learning schedule</li>
                  <li>• Recorded sessions access</li>
                  <li>• Online community access</li>
                </ul>
              </div>
            </div>

            {/* Course Details */}
            <div className="max-w-2xl mx-auto mt-12 text-center">
              <div className="bg-black/20 backdrop-blur-md p-6 rounded-lg">
                <h3 className="text-xl font-orbitron text-blue-400 mb-4">Course Structure</h3>
                <ul className="text-gray-300">
                  <li>Duration: 5 months</li>
                  <li>4 classes per week</li>
                  <li>Early bird period: 11-25th January 2025</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="px-4 py-16 mb-20">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-2xl md:text-4xl font-orbitron text-white mb-8">
            Ready to Begin?
          </h2>
          <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-6">
            <Link 
              to="/registration" 
              className="inline-block px-8 py-4 bg-blue-600 text-white font-orbitron rounded-lg hover:bg-blue-500 transition-colors duration-300 shadow-lg"
            >
              Apply Now
            </Link>
            <a 
              href="https://drive.google.com/file/d/1rOoT_KwATNAL-5IH1LDrxW1j3-lKBxRo/view?usp=sharing" 
              download
               target="_blank"
               rel="noreferrer"
              className="inline-block px-8 py-4 border-2 border-blue-400 text-blue-400 font-orbitron rounded-lg hover:bg-blue-900/20 transition-all duration-300 shadow-lg"
            >
              Download Brochure
            </a>
          </div>
        </div>
      </section>
      </main>
  );
};

const benefits = [
  {
    title: "AI-Powered Trading",
    description: "Learn to integrate AI with traditional algorithmic trading strategies."
  },
  {
    title: "Expert Mentorship",
    description: "Get guidance from experienced traders and industry professionals."
  },
  {
    title: "Real Fund Management",
    description: "Manage live funds after completing the training program."
  },
  {
    title: "Risk-Free Learning",
    description: "Test strategies in a controlled environment before live trading."
  },
  {
    title: "Comprehensive Training",
    description: "Master Python, MQL5, and AI for building advanced strategies."
  },
  {
    title: "Career Opportunity",
    description: "Start earning by managing funds with no upfront costs."
  }
];

export default CoursePage;